import { App, Component } from "vue";
import vcDatePicker from '@/components/reusables/vcDatePicker.vue'
import vcDatePickerTime from '@/components/reusables/vcDatePickerTime.vue'
import vcDateRange from '@/components/reusables/vcDateRange.vue'
import vcAmount from '@/components/reusables/vcAmount.vue'
import UpdateNameModal from '@/components/modals/forms/UpdateNameModal.vue'
import emiratesIDInput from '@/components/input/emiratesIDInput.vue'
import uploadDocuments from '@/components/modals/forms/uploadDocuments.vue'
import invoiceHasPending from '@/components/modals/forms/InvoiceHasPending.vue'
import extensionItem from '@/components/reusables/extensionUploadDocumentItem.vue'

// New Component
import vcTables from '@/components/reusables/NEW/vcTables.vue'
import vcDatePickerv2 from '@/components/reusables/NEW/vcDatePicker.vue'
import PasswordReset from "@/components/modals/PasswordReset.vue";


import vcTablesNew from '@/components/reusables/NEW/Optimize/vcTablesNew.vue'
import iFirst from '@/components/NEW/Invoice/iFirst.vue'
import iSecond from '@/components/NEW/Invoice/iSecond.vue'
import iThird from '@/components/NEW/Invoice/iThird.vue'
import UploadReceipt from '@/components/NEW/Modals/UploadReceipt.vue'
import InvoiceDetails from '@/components/NEW/Modals/InvoiceDetails.vue'
import QuickLead from '@/components/NEW/Modals/QuickLead.vue'
import InvoiceModal from '@/components/NEW/Modals/InvoiceModal.vue'
import vcAmountV2 from '@/components/reusables/NEW/vcAmountV2.vue'
import vcSockets from '@/components/reusables/NEW/vcSockets.vue'
import vcActions from '@/components/reusables/NEW/vcActions.vue'



export function initSkyeComponent(app: App<Element>) {
    const data = [
        { key: 'vc-date-picker', component: vcDatePicker },
        { key: 'vc-date-picker-time', component: vcDatePickerTime },
        { key: 'vc-date-picker-range', component: vcDateRange },
        { key: 'vc-amount', component: vcAmount },
        { key: 'vue-input-mask', component: emiratesIDInput },
        { key: 'vc-update-name', component: UpdateNameModal },
        { key: 'vc-extension-update', component: uploadDocuments },
        { key: 'vc-invoice-has-pending', component: invoiceHasPending },
        { key: 'vc-extension-item', component: extensionItem },
        { key: 'vc-tables', component: vcTables },
        { key: 'vc-date-picker-v2', component: vcDatePickerv2 },
        { key: 'vc-modal-password-reset', component: PasswordReset },
        { key: 'vc-invoice-first', component: iFirst },
        { key: 'vc-invoice-second', component: iSecond },
        { key: 'vc-invoice-third', component: iThird },
        { key: 'vc-tables-new', component: vcTablesNew },
        { key: 'vc-modal-upload-receipt', component: UploadReceipt },
        { key: 'vc-modal-invoice-details', component: InvoiceDetails },
        { key: 'vc-modal-quick-lead', component: QuickLead },
        { key: 'vc-modal-invoice', component: InvoiceModal },
        { key: 'vc-amount-v2', component: vcAmountV2 },

        { key: 'vc-sockets', component: vcSockets },
        { key: 'vc-actions', component: vcActions },

    ]

    data.forEach(element => {
        app.component(element.key, element.component);
    })

}

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_BrowserModal = _resolveComponent("BrowserModal")!
  const _component_OnlinePaymentInfoModal = _resolveComponent("OnlinePaymentInfoModal")!
  const _component_BankDetailsModal = _resolveComponent("BankDetailsModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_component_router_view, {
      key: _ctx.$route.fullPath
    })),
    _createVNode(_component_BrowserModal),
    _createVNode(_component_OnlinePaymentInfoModal),
    _createVNode(_component_BankDetailsModal)
  ], 64))
}

import { defineComponent, ref } from "vue";
import Invoice from "@/core/services/car/InvoiceService";
import router from "@/router";

export default defineComponent({
  props: {
    lead_id: {
      type: Number,
      required: true,
    },
    customer_id: {
        type: Number,
        required: true,
    },
    merchant_reference: {
        type: String,
        required: true,
    }
    
  },

  setup(props) {

    const isOpen = ref(false)


    const callEvent = async() => {
      await Invoice.makeInvoiceEvent({
        driver_id: props.lead_id,
        type: 'car'
      })

      router.push({
          name: "customer-details",
          params: { customer_id: props.customer_id },
          query: { lead_id: props.lead_id }
        });

        open(false)
    }

    const open = (value = false) => {
        isOpen.value = value
    }

    return {
        open,
        isOpen,
      callEvent,
    };
  },
});

enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  SWITCH_ROLE = "SWITCH_ROLE",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  LIST_CUSTOMER = "listCustomer",
  UPDATE_CUSTOMER_NAME = "updateCustomerName",
  ADD_QUICK_CUSTOMER = "addQuickLead",
  GET_CUSTOMER_DETAILS = "getCustomerDetails",
  GET_CUSTOMER_DETAILS2 = "getCustomerDetails2",
  GET_CUSTOMER_STATUS_COUNT = "getCustomerStatusCount",
  GET_SALES_AGENT = "getSkyeAgentList",
  GET_DUE_DATE_TASK_COUNT = "getCarTaskDueDateCount",
  GET_FILTERED_DUE_DATE_TASK_COUNT = "getCarFilteredTaskDueDateCount",
  TRANSFER_LEADS = "transderLeads",
  IMPORT_CUSTOMER_LIST = "importCustomerList",
  EXPORT_CUSTOMER_LIST = "exportCustomerList",
  GET_CALENDAR_DETAILS = "getCalendarDetails",
  ADD_LEAD_TASK = "addLeadTask",
  GET_MANUFACTURERS = "getManufacturers",
  GET_BIKE_MANUFACTURERS = "getBikeManufacturers",
  GET_MODELS = "getModels",
  GET_TRIMS = "getTrims",
  GET_COUNTRIES = "getCountries",
  ADD_QUOTES = "addQuotes",
  GET_DRIVER_DETAILS_LIST = "driverDetailsList",
  SEND_QUOTE = "sendQuote",
  FETCH_QUOTE = "fetchQuote",
  FETCH_MASTER_FEES = "FETCH_MASTER_FEES",
  EDIT_QUOTES = "editQuotes",
  GET_INSURANCE_POLICIES = "getInsurancePolicies",
  ADD_INVOICE = "addInvoice",
  RUN_INVOICE_SCHEDULER = "runInvoiceScheduler",
  GET_INVOICE_LIST = "getInvoiceList",
  GET_INVOICE_LIST_COUNT = "getInvoiceListCount", 
  GET_INVOICE_LIST_OLD = "GET_INVOICE_LIST_OLD",
  FETCH_INVOICE_LIST = "fetchInvoiceList",
  UPDATE_INVOICE = "updateInvoice",
  GET_USER_LIST = "getUserList",
  GET_ROLES = "getRoles",
  GET_UNDERWRITERS_LIST = "getUnderwriters",
  ADD_USER = "addUser",
  FETCH_USER = "fetchUser",
  EDIT_USER = "editUser",
  GET_ESTIMATED_VALUE = "getEstimatedValue",
  GET_LEAD_COMP_QUOTES_LIST = "getLeadCompQuotesList",
  MERGE_QUICK_LEAD = "mergeQuickLead",
  CHECK_EXIST_CUSTOMER = "CHECK_EXIST_CUSTOMER",
  FETCH_CAR_LEAD = "fetchCarLead",
  GET_LEAD_TPL_QUOTES_LIST = "getLeadTplQuotesList",
  GET_LEAD_TASK_LIST = "getLeadTaskList",
  ADD_NOTES = "addNotes",
  PULL_NOTES = "pullNotesList",
  DELETE_LEAD_TASK = "deleteLeadTask",
  GET_CUSTOMER_ACTIVITY_LOGS = "getCustomerActivityLogs",
  GET_CUSTOMER_STATUS_LOGS = "getCustomerStatusLogs",
  GET_LEAD_ACTIVITY_LOG = "getLeadActivityLog",
  GET_LEAD_STATUS_LOG = "getLeadStatusLogs",
  SEARCH_CUSTOMERS = "searchCustomer",
  ADD_USER_DETAILS = "addUserDetails",
  GET_POLICIES_LIST = "getPolicyList",
  ADD_BIKE_USER_DETAILS = "addBikeUserDetails",
  EDIT_BIKE_USER_DETAILS = "editBikeUserDetails",
  GET_SETTING_VALUE = "getSettingValue",
  GET_POLICIES_LIST_COUNT = "getPolicyListCount",
  GET_INVOICE_DOCUMENTS = "getInvoiceDocuments",
  GET_LEAD_POLICIES_LIST = "getLeadPolicyList",
  GET_POLICY_DETAILS = "getPolicyDetails",
  GET_USER_TYPES = "getUserTypes",
  GET_POLICY_START_DATE_DAYS_RESTRICT = "GET_POLICY_START_DATE_DAYS_RESTRICT",
  GET_VAT = "GET_VAT",
  GET_VIEW_ALL_DOCUMENTS = "GET_VIEW_ALL_DOCUMENTS",
  UPLOAD_POLICY = "uploadPolicy",
  GET_POLICY_DOCUMENTS = "getPolicyDocuments",
  DOWNLOAD_ZIP_DOCUMENTS = "downloadZipDocuments",
  GET_ALL_DOCUMENTS = "getAllDocuments",
  DELETE_DOCUMENT = "deleteDocument",
  CHECK_LEAD_MERGE = "checkLeadMerge",
  MERGE_LEAD = "mergeLead",
  GET_TASK_RESPONSE = "getTaskResponse",
  GET_DOM_SALES_AGENTS = "GetSalesAgentForDom",
  GET_DOM_SALES_AGENT_MANAGER_TEAMLEADER = "GetSalesAgentManagerTeamLeaderForDom",
  GET_LEAD_DOCUMENTS = "getLeadDocuments",
  GET_POLICY_SALES_AGENT = "getPolicySalesAgent",
  GET_TASK_REASONS = "getTaskReasons",
  GET_TEAM_LEADERS_LIST = "getTeamLeadersList",
  GET_INSURANCE_COMPANIES = "getInsuranceCompanies",
  GET_MANUAL_QUOTES = "getManualQuotes",
  SEND_MANUAL_QUOTE = "sendManualQuotes",
  ADD_MANUAL_QUOTES = "addManualQuotes",
  DOWNLOAD_MANUAL_QUOTES = "downloadManualQuotes",
  ADD_INVOICE_DOCUMENTS = "addInvoiceDocuments",
  GET_EMAIL_TEMPLATE = "getEmailTemplate",
  SEND_EMAIL = "sendEmail",
  GET_EMAIL_LIST = "getEmailList",
  EXPORT_TRANSACTION_APPROVALS = "exportTransactionApprovals",
  GET_AMEND_INVOICE_INFORMATION = "GET_CHILD_INVOICES",
  INITIATE_REFUND = "INITIATE_REFUND",
  MAKE_INVOICE_EVENT = "MAKE_INVOICE_EVENT",
  GET_CHILD_INVOICES = "getAmendInvoiceInformation",
  ADD_AMEND_INVOICE = "addAmendInvoice",
  GET_SMS_LIST = "getSmsList",
  UPLOAD_POLICY_DOCUMENT = "uploadPolicyDocuments",
  GET_TEMP_POLICY_DOCUMENT = "getTempPolicyDocuments",
  GET_TEMP_POLICY_CANCEL_DOCUMENT = "getTempPolicyCancelDocuments",
  GET_DOC_TYPES = "getDocTypes",
  GET_CANX_DOC_TYPES = "getCanxDocTypes",
  DELETE_TEMP_DOCUMENT = "deleteTempDoc",
  UPDATE_TEMP_DOCUMENT = "updateTempDoc",
  SAVE_AS_DRAFT = "saveAsDraft",
  GET_POLICY_MAIL_LIST = "getPolicyMailList",
  SEND_CUSTOMER_EMAIL = "sendCustomerEmail",
  SEND_BROKER_EMAIL = "sendBrokerEmail",
  GET_NOTIFICATION_LIST = "getNotificationList",
  EXPORT_POLICY_ASSIGNED = "exportPolicyAssigned",
  MAKE_NOTIFICATION_READ = "makeNotificationRead",
  GET_NOTIFICATION_COUNT = "getNotificationCount",
  TRANSFER_SALE = "transferSale",
  TRANSFER_UNDERWRITER = "transferUnderwriter",
  UPDATE_CUSTOMER_EMAIL = "updateCustomerEmail",
  GET_ALL_NOTIFICATION_LIST = "getAllNotificationList",
  GET_ROUTE_LIST = "getRoutes",
  GET_CANCELLATION_REASON = "getCancellationReason",
  CANCEL_POLICY_REQUEST = "cancelPolicyRequest",
  GET_POLICY_CANCELLATION_DOCUMENT = "getPolicyCancellationDocument",
  UNDERWRITTER_CANCEL_POLICY_REQUEST = "underwritterCancelPolicy",
  GET_SEARCH_RESULT = "getSearchResult",
  GET_SEARCH_RESULT_MO = "getSearchResultMO",
  GET_PAYTO_MASTERS= "getPaytoMasters",
  GET_INSURANCE_PAYMET_TYPE = "getInsurancePaymentType",
  CANCEL_POLICY_APPROVAL = "cancelPolicyApproval",
  GET_PAYMENT_TYPES = "getPaymentTypes",
  GET_REFUND_REASON = "getRefundReason",
  POLICY_REFUND_REQUEST = "policyRefundRequest",
  GET_REFUND_POLICIES_LIST = "getRefundPolicyList",
  GET_REFUND_POLICIES_LIST_COUNT = "getRefundPolicyCount",
  REFUND_POLICY_APPROVAL = "refundPolicyApproval",
  RESET_POLICY_DOCUMET_STATUS = "resetPolicyDocuments",
  GET_CANCELLED_POLICIES_LIST = "getCancelledPoliciesList",
  GET_CALLBACK_REQUEST_LIST  = "getCallbackRequestList",
  GET_CALLBACK_PET_REQUEST_LIST  = "getCallbackPetRequestList",
  GET_CALLBACK_EXPAT_REQUEST_LIST  = "getExpatInsuranceCallBackList",
  GET_CONTACT_US_LIST  = "GET_CONTACT_US_LIST",
  EXPORT_CALLBACK_REQUEST = "exportCallbackRequestList",
  EXPORT_CALLBACK_PET_REQUEST = "exportCallbackpetRequestList",
  EXPORT_CALLBACK_EXPAT_REQUEST = "exportCallbackExpatRequestList",
  EXPORT_CONTACT_US_REQUEST = "exportCONTACT_USRequestList",
  GET_PREVIOUS_POLICY_STATUS = "getPreviousPolicyStatus",
  GET_PREVIOUS_POLICY_DOCUMENTS = "getPreviousPolicyDocuments",
  GET_OCR_VALUE = "GET_OCR_VALUE",
  REUPLOAD_DOCUMENT = "reUploadDocument",
  GET_COUNTRY_CODE_LIST  = "getCountryCodeList",
  UPDATE_CUSTOMER_PHONE = "updateCustomerPhone",
  SEND_OTP = "sendOTP",
  VERIFY_OTP_AND_UPDATE_PHONE_NUMBER = "verifyOTPandUpdatePhoneNumber",
  GET_PROMOTION_CODE_LIST  = "getPromotionCodeList",
  GET_PROMOTION_CODE_TYPES = "getPromotionCodeTypes",
  ADD_PROMOTION_CODE = "addPromotionCode",
  FETCH_PROMOTION_CODE =  "showPromotionCode",
  CHECK_PROMO_EXISTS   = "CHECK_PROMO_EXISTS",
  UPDATE_PROMOTION_CODE = "updatePromotionCode",
  CHECK_TOKEN = "checkToken",
  REFRESH_TIME = "REFRESH_TIME",
  GET_POLICY_LOGS = "getPolicyLogs",
  GET_DASHBOARD_OVERVIEW = "getDashboardOverview",
  GET_SALES_TARGET_LIST = "getSalesTargetList",
  SAVE_SALES_TARGET = 'saveSalesTarget',
  GET_LEADS_TARGET_LIST = 'getLeadsTargetList',
  SAVE_LEADS_TARGET = 'saveLeadsTarget',
  GET_SALES_PERSON_INFO = "getSalesPersonInfo",
  GET_SALES_STATISTICS = "getSalesStatistics",
  GET_SALES_OTHERS = "getSalesOthers",
  GET_SALES_AGENT_MANAGER = "getSalesAgentManager",
  GET_SALES_UNDERWRITER = "getSalesUnderwriter",
  GET_DASHBOARD_GET_USER_LOGS = "geDashboardtUserLogs",
  GET_DASHBOARD_COD_TRANSACTION = "geDashboardtCodTransaction",
  GET_DASHBOARD_ONLINE_TRANSACTION = "geDashboardtOnlineTransaction",
  GET_DASHBOARD_DIRECT_TRANSACTION = "geDashboardtDirectTransaction",
  GET_DASHBOARD_LEADS_NEW = "geDashboardLeadsNew",
  GET_DASHBOARD_LEADS_LOST = "geDashboardLeadsLost",
  GET_DASHBOARD_LEADS_PENDING = "geDashboardLeadsPending",
  GET_MANUAL_ORDER_LIST = "getManualOrderList",
  GET_LEAD_BY_CUSTOMER = "getLeadByCustomer",
  ADD_TEMP_MANUAL_ORDER = "addTempManualOrder",
  ADD_MANUAL_ORDER = "addManualOrder",
  FETCH_MANUAL_ORDER = "showManualOrder",
  UPDATE_NOTIFICATION_CLICK = "updateNotificationClick",
  DELETE_MANUAL_ORDER = "deleteManualOrder",
  GET_APPS = "getApps",
  GET_DOCUMENT_LOG_LIST ="getDocumentLogList",
  ADD_DOCUMENT_LOG = "addDocumentLog",
  UPDATE_CLOSED_LEAD = "updateClosedLead",
  SEND_EMAIL_IT_TEAM= "sendEmailItTeam",
  SEND_EMAIL_PAYMENT_CUSTOMER= "sendEmailPaymentCustomer",
  GET_POLICY_REPORT_LIST = "getPolicyReportList",
  EXPORT_POLICY_REPORT_LIST = "getExportPolicyReportList",
  GET_POLICY_STATUS_LIST = "getPolicyStatusList",
  GET_AGENT_SALES_REPORT_LIST = "getAgentSalesReportList",
  EXPORT_AGENT_SALES_REPORT_LIST = "getExportAgentSalesReportList",
  GET_SKYE_LOGIN_INFO_REPORT_LIST = "getSkyeLoginInfoReportList",
  EXPORT_SKYE_LOGIN_INFO_REPORT_LIST = "getExportSkyeLoginInfoReportList",
  GET_DOM_SKYE_USERS = "getDomSkyeUsersList",
  GET_ACCOUNT_MASTER_REPORT_LIST = "getAccountMasterReportList",
  EXPORT_ACCOUNT_MASTER_REPORT_LIST = "getExportAccountMasterReportList",
  GET_APPROVED_INVOICE_REPORT = "getApprovedInvoiceReportList",
  EXPORT_APPROVED_INVOICE_REPORT_LIST = "getExportApprovedInvoiceReportList",
  GET_ACCOUNT_MASTER_REFUND_RECORD_COUNT = "getAccountMasterRefundRecordCount",
  GET_QUOTE_AUTO_SUGGEST = "getQuoteAutoSuggest",
  GET_SALES_ACHIEVEMENTS = "getSalesAchievements",
  GET_LEAD_SOURCES = "getLeadSources",
  GET_LEAD_BY_SOURCE_REPORT_LIST = "getLeadBySourceReportList",
  EXPORT_LEAD_BY_SOURCE_REPORT_LIST = "getExportLeadBySourceReportList",
  GET_LEAD_BY_SOURCE_FILTERS_LIST = "getLeadBySourceFiltersList",
  GET_REPORT_YEARS_LIST = "getReportYearsList",
  GET_REPORT_MONTH_LIST = "getReportMonthsList",
  GET_REPORT_LEAD_SOURCES_LIST = "getReportLeadSourcesList",
  GET_REPORT_UTM_SOURCES_LIST = "getReportUtmSourcesList",
  GET_REPORT_UTM_MEDIUM_LIST = "getReportUtmMediumList",
  GET_REPORT_UTM_CAMPAIGN_LIST = "getReportUtmCampaignList",
  GET_REPORT_UTM_CONTENT_LIST = "getReportUtmContentList",
  GET_CUSTOMER_VIEW_ACTIVITIES = "getCustomerViewActivities",
  GET_MARKETING_DASHBOARD = "getMarketingDashboard",
  GET_ACTIVE_LEAD_POLICY_DOCUMENTS = "getActiveLeadPolicyDocuments",
  GET_CLOSED_LEAD_REPORT_LIST="getClosedLeadReportList",
  GET_AL_MANARAH_REPORT = "getAlmanarahReportList",
  GENERATE_AL_MANARAH_REPORT="generateAlmanarahReport",
  GET_QUOTE_REQUEST_LISTING = "getQuoteRequestListing",
  GET_QUOTE_REQUEST_DOCS = "getQuoteRequestDocs",
  GET_INSURANCE_PROVIDERS = "getInsuranceProviders",
  GET_INSURANCE_POLICY_BY_TYPE_LIST = "getInsurancePolicyByTypeList",
  GET_UPDATE_QUOTE_REQUEST_DOCUMENT = "getUpdateQRDoc",
  APPROVE_QUOTE_REQUEST = "getApproveQuoteRequest",
  SEND_QUOTE_REQUEST = "sendQuoteRequest",
  EXPORT_CLOSED_LEADS_REPORT_LIST="exportClosedLeadReportList",
  GET_DELETED_LEAD_REPORT_LIST="getDeletedLeadReportList",
  EXPORT_DELETED_LEADS_REPORT_LIST="exportDeletedLeadReportList",
  GET_DISCREPANCY_REPORT_LIST="getDiscrepancyReportList",
  EXPORT_DISCREPANCY_REPORT_LIST="exportDiscrepancyReportList",
  GET_ACCOUNTS_DASHBOARD = "getAccountsDashboard",
  GET_CHILD_INVOICES_BY_POLICY="getChildInvoiceByPolicy",
  GET_EXTENSIONS="GET_EXTENSIONS",
  UPLOAD_AMEND_DOCUMENT="UPLOAD_AMEND_DOCUMENT",
  DELETE_QR_DOCUMENT = "deleteQRdocument",
  IS_LEAD_RENEWAL_PENDING = "isLeadRenewalPending",
  REQUEST_PAYMENT_LINK = "requestPaymentLink",
  SEND_PAYMENT_LINK = "sendPaymentLink",
  GET_BANK_DETAILS = "getBankDetails",
  GET_ONLINE_PAYMENT_INFO="getOnlinePayment",
  GET_ADMIN_DASHBOARD_REPORT="getAdminDashboardReport",
  EXPORT_ADMIN_DASHBOARD_REPORT="exportAdminDashboardReport",
  GET_LEAD_STATUS_COUNT_TOOLBAR="getLeadStatusCountToolbar",
  GET_AGENT_BY_SETTING="getAgentBySetting",
  GET_LEAD_STATUS_FILTER_COUNT="getLeadStatusFilterCount",
  GET_ACTIVE_DOM_SALES_AGENTS="getActiveDomSalesAgent",
  GET_ACTIVE_UNDERWRITERS="GET_ACTIVE_UNDERWRITERS",
  GET_APPROVAL_INVOICE_LIST_COUNT="getApprovalInvoiceListCount",
  GET_CUSTOMER_LEADS_FOR_MERGE="getCustomerLeadForMerge",
  UPDATE_CUSTOMER_OTHER_CONTACT_INFO="updateCustomerOtherContactInfo",
  INITIATE_AMEND_REFUND="INITIATE_AMEND_REFUND",
  UPDATE_QUOTE_DOCS_NOTES="UPDATE_QUOTE_DOCS_NOTES",
  GET_CAR_YEARS="GET_CAR_YEARS",
  GET_DASHBOARD_DETAILS_REPORT_LIST = "getDashboardDetailsReportList",
  EXPORT_DASHBOARD_DETAILS_REPORT_LIST = "exportDashboardDetailsReportList",
  GET_LEAD_SOURCE_FILTER_LIST = "getLeadSourceFilterList",
  GET_CHECK_TRIM="getCheckTrim",
  UPDATE_DATA_POLICY = "updateDataPolicy",
  DELETE_QUOTE_REQUEST="deleteQuoteRequest",
  SWITCH_APP = 'SWITCH_APP',
  SWITCH_APP_RELOAD = 'SWITCH_APP_RELOAD',
  GET_NEW_AUTH_CHECK = "getNewAuthCheck",
  GET_SPLIT_PAYMENT_LIST = "getSplitPaymentList", 
  FETCH_SPLIT_PAYMENT_LIST="fetchSplitPaymentList",
  UPDATE_SPLIT_PAYMENT_STATUS = "updateSplitPaymentStatus",
  GET_SPLIT_PAYMENT_TYPES = "getSplitPaymentTypes",
  GET_CUSTOMER_BOUGHT_FROM = "getCustomerBoughtFrom",

}

enum Mutations {
  // mutation types
  SET_APPS = 'setApps',
  SET_LOADALL = 'SET_LOADALL',
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  PURGE_AUTH_SIGNIN = "singinPurge",
  SET_AUTH = "setAuth",
  SET_USER_ACL = "setAclRole",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_AUTHENTICATED = "setAuthenticated",
  SET_REFRESH_TIME = "setRefreshTime",
  SET_ERROR = "setError",
  SET_OTP_ERROR = "setOTPError",
  SET_ERRORS = "setErrors",
  SET_EXIST_CUSTOMER = "SET_EXIST_CUSTOMER",
  SET_LEAD_LOADING = "SET_LEAD_LOADING",
  SET_CUSTOMER_ERROR = "SET_CUSTOMER_ERROR",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_CUSTOMER = "assignCustomer",
  SET_CUSTOMER_DETAILS = "setCustomerDetails",
  SET_CUSTOMER_STATUS_COUNT = "setCustomerStatusCount",
  SET_SALES_AGENT_LIST = "setSalesAgentList",
  SET_TASK_DUE_DATE_COUNT = "setTaskDueDateCount",
  SET_LEAD_DETAILS = "setLeadDetails",
  SET_CALENDAR_EVENT = "setCalendarEvents",
  SET_MANUFACTURERS = "setManufacturers",
  SET_BIKE_MANUFACTURERS = "setBikeManufacturers",
  SET_MODELS = "setModels",
  SET_TRIMS = "setTrims",
  SET_COUNTRIES = "setCountries",
  SET_QUOTES_ERROR = "setQuotesError",
  SET_QUOTES_LIST = "setQuotesList",
  SET_EDIT_QUOTE = "setEditQuote",
  SET_MASTER_ADMIN = "SET_MASTER_ADMIN",
  SET_INSURANCE_POLICY_LIST = "setInsurancePolicyList",
  SET_INVOICE_ERROR = "invoiceErrorList",
  SET_INVOICE_LIST = "setInvoiceList",
  SET_LEAD_DRIVER_DETAILS_LIST = "setLeadDriverDetails",
  SET_USER_LIST = "setUserList",
  SET_USER_ERROR = "userErrorList",
  SET_ROLES = "setRoles",
  SET_UNDERWRITERS_LIST = "setPolicyAgents",
  SET_EDIT_USER = "setEditUser",
  SET_ESTIMATED_VALUE = "setEstimatedValue",
  SET_LEAD_COPM_QUOTES_LIST = "setLeadCompQuotesList",
  SET_CAR_LEAD = "setCarLead",
  SET_LEAD_TPL_QUOTES_LIST = "setLeadTplQuotesList",
  SET_LEAD_TASK_LIST = "setLeadTaskList",
  SET_TASK_ERROR = "setTaskError",
  SET_LEAD_NOTES_LIST = "setLeadNotesList",
  SET_CUSTOMER_ACTIVITIES = "setCustomerActivities",
  SET_NOTES_ERROR = "setNotesError",
  SET_CUSTOMER_STATUS = "setCustomerStatusLog",
  SET_LEAD_ACTIVITY_LOG = "setLeadActivityLog",
  SET_LEADS_ERROR = "setLeadsError",
  SET_LEAD_STATUS_LOG = "setLeadStatusLog",
  SET_CUSTOMER_SEARCH_RESULT = "setCustomerSearchResults",
  SET_OTP_LOADING = "setOTPLoading",
  SET_OTP_TOKEN = "setOTPToken",
  SET_OTP_RESPONSE = "setOTPResponse",
  SET_USER_DETAILS = "setUserDetails",
  SET_POLICY_LIST = "setPolicyList",
  SET_POLICY_LIST_COUNT = "setPolicyListCount",
  SET_POLICY_ERROR = "setPolicyError",
  RESET_POLICY_ERROR = "RESET_POLICY_ERROR",
  SET_DOCUMENT_DETAILS = "setDocumentDetails",
  SET_LEAD_POLICY_LIST = "setLeadPolicyList",
  SET_DEBIT_VALUE = "setDebitValue",
  SET_POLICY_DETAILS = "setPolicyDetails",
  SET_USER_TYPE = "setUserTypes",
  SET_SHOW_NOTES_DOM = "setShowNotesDom",
  SET_SHOW_TASK_DOM = "setShowTaskDom",
  SET_DOM_STATUS = "setDomStatus",
  SET_QUOTES_DOM_STATUS = "setQuotesDom",
  SET_INVOICE_DOM_STATUS = "setInvoiceDom",
  SET_LEAD_LOG_DOM_STATUS = "setLeadLogDomStatus",
  SET_POLICY_DOCUMENTS = "setPolicyDocuments",
  SET_POLICY_DOCUMENT_DOM_STATUS = "setPolicyDocumentDom",
  SET_LEAD_MERGE_STATUS = "setLeadMergeStatus",
  SET_LEAD_LIST_DOM_STATUS = "setLeadListDomStatus",
  SET_TASK_RESPONSE = "setTaskResponse",
  SET_MASTER_ERROR = "setMasterError",
  SET_DOM_SALES_AGENTS = "setDomSalesAgent",
  SET_DOM_SALES_AGENTS_MANAGER_TEAM_LEADER = "setDomSalesAgentManagerTeamLeader",
  SET_USER_DOM = "setUserDom",
  SET_LEAD_DOCUMENTS = "setLEadDocuments",
  SET_POLICY_SALES_AGENT_LIST = "setPolicySalesAgent",
  SET_TASK_REASONS = "setTaskReasns",
  SET_TEAM_LEADERS_LIST = "setTeamLeadersList",
  SET_QUICK_LEAD_ERROR = "setQuickLeadError",
  SET_EDIT_DRIVER_DETAILS_DOM = "setEditDriverDetailsDom",
  SET_DISABLE_BUTTON = "setDisableButton",
  SET_CANCEL_LOADING = "setCancelLoading",
  SET_MANUAL_QUOTES = "setManualQuotes",
  SET_MANUAL_QUOTES_DOM_STATUS = "setManualQuotesDom",
  SET_INVOICE_DOCUMENT_DOM = "setInvoiceDocumentDom",
  SET_EMAIL_TEMPLATE = "setEmailTemplate",
  SET_EMAIL_ERROR = "setEmailError",
  SET_EMAIL_LIST = "setEmailList",
  SET_EXPORT_DETAILS = "setExportDetails",
  SET_INVOICE_APPROVAL_DOM = "setInvoiceApprovalDom",
  SET_AMEND_INVOICE_INFORMATION = "setAmendInvoiceInformation",
  SET_OPEN_DOCUMENTS = "setOpenDocuments",
  SET_OPEN_UPLOAD_INVOICE = "setOpenUploadInvoice",
  SET_INVOICE_ID = "setInvoiceId",
  SET_UPLOAD_RECEIPT = "SET_UPLOAD_RECEIPT",
  SET_SMS_LIST = "setSmsList",
  SET_SMS_ERROR = "setSmsError",
  SET_TEMP_DOCUMENTS = "setTempPolicyDocuments",
  SET_TEMP_CANCEL_DOCUMENTS = "setTempPolicyCancelDocuments",
  SET_DOC_TYPES = "setDocTypes",
  SET_CANX_DOC_TYPES = "setCanxDocTypes",
  SET_POLICY_MAIL_LIST = "setPolicyMailList",
  SET_POLICY_DOCUMENT_LOADING = "SET_POLICY_DOCUMENT_LOADING",
  SET_CUSTOMER_EMAIL_DISABLE_BUTTON = "setCustomerEmailDisableButton",
  SET_BROKER_EMAIL_DISABLE_BUTTON = "setBrokerEmailDisableButton",
  SET_NOTIFICATION_LIST = "setNotificationList",
  SET_NOTIFICATION_ERROR = "setNotificationError",
  SET_NOTIFICATION_COUNT = "setNotificationCount",
  SET_DISABLE_TRANSFER_BUTTON = "setDisableTransferButton",
  UPDATE_EDIT_QUOTE_EMAIL = "setInvoiceCustomerEmail",
  UPDATE_EDIT_CUSTOMER_EMAIL = "setCustomerEmail",
  SET_ROUTE_LIST = "setRouteList",
  SET_ROUTE_LIST_INITIAL = "setRouteListInitial",
  SET_LOGIN_ERROR = "setLoginError",
  SET_POLICY_LIST_DOM = "setPolicyListDom",
  SET_CANCELLATION_REASON = "setCancellationReason",
  SET_POLICY_CANCELLATION_DOCUMENTS = "setCancellationDocument",
  SET_SEARCH_RESULT = "setSearchResult",
  SET_SEARCH_RESULT_MO = "setSearchResultMo",
  SET_PAYTO_MASTERS = "setPaytoMasters",
  SET_INSURANCE_PAYMENT_TYPE = "setInsurancePaymentType",
  SET_ALL_NOTIFICATION_LIST = "setAllNotificationList",
  SET_PAYMENT_TYPES = "setPaymentTypes",
  SET_REFUND_REASON = "setRefundReason",
  SET_POLICY_DOCUMENT_MODAL_DOM_STATUS= "setPolicyDocumentsModalDom",
  SET_POLICY_DOM_DETAILS = "setPolicyDomDetails",
  SET_REFUND_DOM_DETAILS = "setRefundDomStatus",
  SET_CANCELLED_POLICIES_LIST = "setCancelledPoliciesList",
  SET_CALLBACK_REQUEST_LIST  = "setCallbackRequestList",
  SET_CALLBACK_PET_REQUEST_LIST  = "setCallbackPetRequestList",
  SET_CALLBACK_EXPAT_REQUEST_LIST  = "setCallbackExpatRequestList",
  SET_CONTACT_US  = "SET_CONTACT_US",
  SET_EXPORT_CALLBACK_REQUEST = "setExportDetails",
  SET_EXPORT_CALLBACK_PET_REQUEST = "setExportPetDetails",
  SET_POLICY_SAVE_BUTTON_DISABLED = "disablePolictsubmitButton",
  SET_POLICY_DRAFT_SAVE_BUTTON_DISABLED = "disablePolicyDraftDisabledButton",
  SET_PREVIOUS_POLICIES_STATUS = "setPreviousPolicyStatus",
  SET_PREVIOUS_POLICY_DOCUMENTS = "setPreviousPolicyDocuments",
  SET_MODAL = "setPolicyModal",
  SET_MODAL_VALUE = "setPolicyModalValue",
  SET_POLICY_ACTIVE = "setPolicyActive",
  SET_POLICY_LOGS = "setPolicyLogs",
  SE_TEMP_LOADING_DOC = "SetTempLoading",
  SET_PA_LOADING = "SET_PA_LOADING",
  SET_EDIT_CANCELLATION_LOADING = "SET_EDIT_CANCELLATION_LOADING",
  SET__REFUND_MODAL = "SET__REFUND_MODAL",
  SET__DISCREPANCY_MODAL = "SET__DISCREPANCY_MODAL",
  SET__DISCREPANCY_MODAL_TEXT = "SET__DISCREPANCY_MODAL_TEXT",
  SET_IS_DISCREPANCY_ERROR = "SET_IS_DISCREPANCY_ERROR",
  SET__DISCREPANCY_MODAL_TEXT_ERROR = "SET__DISCREPANCY_MODAL_TEXT_ERROR",
  SET_OPEN_VIEW_ALL_DOCUMENTS = "SET_OPEN_VIEW_ALL_DOCUMENTS",
  SET_DATA_VIEW_ALL_DOCUMENTS = "SET_DATA_VIEW_ALL_DOCUMENTS",
  SET_LOADING_VIEW_ALL_DOCUMENTS = "SET_LOADING_VIEW_ALL_DOCUMENTS",
  SET_MODAL_LOADING = "setPolicyModalLoading",
  SET_DOCUMENT_ERROR = "setDocumentError",
  SET_POLICY_VIEW = 'setPolicyView',

  SET_COUNTRY_CODE_LIST  = "setCountryCodeList",
  UPDATE_EDIT_QUOTE_PHONE = "updateCustomerPhone",
  SET_PROMOTION_CODE_LIST = "setPromotionCodeList",
  SET_PROMOTION_CODE_ERROR = "setPromotionCodeError",
  SET_PROMOTION_CODE_TYPES = "setPromotionCodeTypes",
  SET_PROMOTION_CODE = "setPromotionCode",
  SET_DASHBOARD_TOTAL = "setDashboardTotalSales",
  SET_DASHBOARD_STATISTICS_LEAD_BY_SOURCE = "setStatisticsLeadBySource",
  SET_DASHBOARD_STATISTICS_LEAD_BY_UTMSOURCE = "setStatisticsLeadByUtmSource",
  SET_DASHBOARD_STATISTICS_LEAD_BY_UTMMEDIUM = "setStatisticsLeadByUtmMedium",
  SET_DASHBOARD_STATISTICS_DEAL_BY_SOURCE = "setstatisticsDealsBySource",
  SET_DASHBOARD_STATISTICS_DEAL_BY_UTMSOURCE = "setstatisticsDealsByUtmSource",
  SET_DASHBOARD_STATISTICS_DEAL_BY_UTMMEDIUM = "setstatisticsDealsByUtmMedium",
  SET_DASHBOARD_POLICY_ISSUE_STAT = "setPolicyIssueStat",
  SET_DASHBOARD_POLICY_CREATOR_STAT = "setPolicyCreatorStat",
  SET_DASHBOARD_TOP_PROVIDERS = "setTopProviders",
  SET_DASHBOARD_TOP_NATIONALITIES = "setTopNationalities",
  SET_DASHBOARD_TOP_BRANDS = "setTopBrands",
  SET_DASHBOARD_TOP_VEHICLES = "setTopVehicles",
  SET_DASHBOARD_TRANSACTION_ONLINE = "setTransactionOnline",
  SET_DASHBOARD_TRANSACTION_COD = "setTransactionCOD",
  SET_DASHBOARD_TRANSACTION_DIRECT = "setTransactionDirect",
  SET_DASHBOARD_USER_LOGS = "setUserLogs",
  SET_DASHBOARD_LEADS_NEW = "setLeadsNew",
  SET_DASHBOARD_LEADS_LOST = "setLeadsLost",
  SET_DASHBOARD_LEADS_PENDING = "setLeadsPending",
  SET_DASHBOARD_POLICY_DATA = "setPolicyData",
  SET_DASHBOARD_QUOTED_VEHICLE = "setQuotedVehicle",
  SET_DASHBOARD_POLICIES_COUPON = "setPoliciesCoupon",
  SET_DASHBOARD_CLOSING_RATIO = "setClosingRatio",
  SET_DASHBOARD_SMS_STAT = "setSmsStat",
  SET_DASHBOARD_DISCOUNT_SUMMARY = "setDiscountSummary",
  SET_DASHBOARD_TOP_SALES_AGENT = "setTopSalesAgent",
  SET_DASHBOARD_SALES_TARGET_SUMMARY = "setSalesTarget",
  SET_DASHBOARD_LEADS_TARGET_SUMMARY = "setLeadsTarget",
  SET_DASHBOARD_ERROR =  "setDashboardOverviewError",
  SET_DASHBOARD_NEW = "setDashboardTotalNewSales",
  SET_DASHBOARD_RENEWAL = "setDashboardTotalRenewalSales",
  SET_DASHBOARD_LLR = "setDashboardTotalLrlSales",
  SET_DASHBOARD_LRL="setDashboardTotalLlrSales",
  SET_DASHBOARD_UNTOUCHED = "setDashboardUntoucedLeads",
  SET_DASHBOARD_LIFETIME = "setDashboardLifetimeSales",
  SET_DASHBOARD_REFUND = "setDashboardTotalRefund",
  SET_DASHBOARD_REVENUE = "setDashboardTotalRevenue",
  SET_SALES_TARGET_LIST = "setSalesTargetList",
  SET_SALES_ERROR = "setSalesError",
  SET_LEADS_TARGET_LIST = "setLeadsTargetList",
  SET_LEADS_TARGET_ERROR = "setLeadsTargetError",
  SET_MANUAL_ORDER_LIST = "setManualOrderList",
  SET_MANUAL_ORDER_ERROR = "setManualOrderError",
  SET_LEAD_BY_CUSTOMER = "setLeadByCustomer",
  SET_LEAD_LOADED = "isLead",
  SET_MANUAL_ORDER = "setManualOrder",
  SET_MANUAL_ORDER_INVOICE = "setManualOrderInvoice",
  SET_DOCUMENT_LOG_LIST ="setDocumentLogList",
  SET_OPEN_DOCUMENT_LOGS = "setOpenDocumentLogs",
  SET_INVOICE="setInvoice",
  SET_INVOICE_MODAL = "setInvoiceModal",
  SET_INVOICE_MODAL_VALUE = "setInvoiceModalValue",
  SET_AI_LOADING = "SET_AI_LOADING",
  SET_MO_LOADING = "SET_MO_LOADING",
  SET_IFA_LOADING = "SET_IFA_LOADING",
  SET_DI_LOADING = "SET_DI_LOADING",
  SET_CHILD_INVOICES = "SET_CHILD_INVOICES",
  SET_UPLOAD_RECEIPT_ERROR = "SET_UPLOAD_RECEIPT_ERROR",
  SET_INVOICE_MODAL_LOADING = "setPolicyModalLoading",
  SET_POLICY_REPORT_LIST = "setPolicyReportList",
  SET_REPORT_ERROR = "setReportError",
  SET_EXPORT_POLICY_REPORT_LIST = "setExportPolicyReportList",
  SET_POLICY_STATUS_LIST = "setPolicyStatusList",
  SET_AGENT_SALES_REPORT_LIST = "setAgentSalesReportList",
  SET_EXPORT_AGENT_SALES_REPORT_LIST = "setExportAgentSalesReportList",
  SET_SKYE_LOGIN_INFO_REPORT_LIST = "setSkyeLoginInfoReportList",
  SET_EXPORT_SKYE_LOGIN_INFO_REPORT_LIST = "setExportSkyeLoginInfoReportList",
  SET_DOM_SKYE_USERS = "setDomSkyeUsers",
  SET_ACCOUNT_MASTER_REPORT_LIST = "setAccountMasterReportList",
  SET_EXPORT_ACCOUNT_MASTER_REPORT_LIST = "setExportAccountMasterReportList",
  SET_EXPORT_APPROVED_INVOICE_REPORT_LIST = "setExportApprovedInvoiceReportList",
  SET_ACCOUNT_MASTER_REFUND_RECORD_COUNT = "setAccountMasterRefundRecordCount",
  SET_QUOTE_AUTO_SUGGEST = "setQuoteAutoSuggest",
  SET_SALES_ACHIEVEMENTS = "setSalesAchievements",
  SET_LEAD_SOURCES = "setLeadSources",
  SET_LEAD_BY_SOURCE_REPORT_LIST = "setLeadBySourceReportList",
  SET_EXPORT_LEAD_BY_SOURCE_REPORT_LIST = "setExportLeadBySourceReportList",
  SET_LEAD_BY_SOURCE_FILTERS_LIST = "setLeadBySourceFiltersList",
  SET_REPORT_YEARS_LIST = "setReportYearsList",
  SET_REPORT_MONTH_LIST = "setReportMonthsList",
  SET_REPORT_LEAD_SOURCES_LIST = "setReportLeadSourcesList",
  SET_REPORT_UTM_SOURCES_LIST = "setReportUtmSourcesList",
  SET_REPORT_UTM_MEDIUM_LIST = "setReportUtmMediumList",
  SET_REPORT_UTM_CAMPAIGN_LIST = "setReportUtmCampaignList",
  SET_REPORT_UTM_CONTENT_LIST = "setReportUtmContentList",
  SET_CUSTOMER_VIEW_ACTIVITIES = "setCustomerViewActivities",
  SET_MARKETING_DASHBOARD = "setMarketingDashboard",
  SET_INVOICE_LIST_COUNT = "setInvoiceListCount",
  SET_QUOTE_REQUEST_LISTING = "setQuoteRequestListing",
  SET_ALL_POLICY_DOCUMENTS = "setAllPolicyDocuments",
  SET_QUOTE_REQUEST_DOCS = "setQuoteRequestDocs",
  SET_INSURANCE_PROVIDERS = "setInsuranceProviders",
  SET_INSURANCE_POLICY_BY_TYPE_LIST = "setInsurancePolicyByTypeList",
  SET_UPDATE_QUOTE_REQUEST_DOCUMENT = "setUpdateQRDoc",
  SET_QR_LOADING = "setQRLoading",
  SET_CLOSED_LEAD_REPORT_LIST = "setClosedLeadReportList",
  SET_AL_MANARAH_REPORT="setAlManarahReportList",
  SET_GENERATE_AL_MANARAH_REPORT_RESPONSE = "setGenerateAlManarahReportResponse",
  SET_EXPORT_CLOSED_LEADS_REPORT_LIST =  "setExportClosedLeadReportList",
  SET_DELETED_LEAD_REPORT_LIST="setDeletedLeadReportList",
  SET_EXPORT_DELETED_LEADS_REPORT_LIST="setExportDeletedLeadReportList",
  SET_DISCREPANCY_REPORT_LIST="setDiscrepancyReportList",
  SET_EXPORT_DISCREPANCY_REPORT_LIST="setExportDiscrepancyReportList",
  SET_ACCOUNTS_DASHBOARD = "setAccountsDashboard",
  SET_CHILD_INVOICES_BY_POLICY= "setChildInvoiceByPolicy",
  SET_UPLOAD_TAB_LEAD_ID = "setUploadTabLeadId",
  SET_UPLOAD_TAB = "setUploadTab",
  SET_FILTERED_TASK_DUE_DATE_COUNT= "setFilteredTaskDateCount",
  SET_QUICK_LINK_NAVIGATION = "setQuickLinkMutation",
  SET_ONLINE_PAYMENT_INFO_MODAL="SET_ONLINE_PAYMENT_INFO_MODAL",
  SET_UPDATE_PHONE_MODAL="SET_UPDATE_PHONE_MODAL",
  SET_QL_STATUS="SET_QL_STATUS",
  SET_EMAIL_UPDATE_STATUS="SET_EMAIL_UPDATE_STATUS",
  SET_EMAIL_IS_ERROR="SET_EMAIL_IS_ERROR",
  SET_EMAIL_ADD_STATUS="SET_EMAIL_ADD_STATUS",
  SET_VIEW_BANK_DETAILS="SET_VIEW_BANK_DETAILS",
  SET_BANK_DETAILS="setBankDetails",
  SET_ONLINE_PAYMENT_INFO="setOnlinePaymentInfo",
  SET_DASHBOARD_DATE_TITLE="setDashboardDateTitle",
  SET_ADMIN_DASHBOARD_REPORT="setAdminDashboardReport",
  SET_ADMIN_DASHBOARD_EXPORT="setAdminDashboardExport",
  SET_LEAD_STATUS_COUNT_TOOLBAR="setLeadStatusCountToolbar",
  SET_LEAD_STATUS_FILTER_COUNT="setLeadStatusFilterCount",
  SET_ACTIVE_DOM_SALES_AGENTS="setActiveDomSalesAgent",
  SET_POLICY_START_DATE_DAYS_RESTRICT = 'SET_POLICY_START_DATE_DAYS_RESTRICT',
  SET_VAT = 'SET_VAT',
  SET_POLICY_START_DATE_DAYS_RESTRICT_UPCOMING = 'SET_POLICY_START_DATE_DAYS_RESTRICT_UPCOMING',
  SET_PHONE_IS_ERROR="SET_PHONE_IS_ERROR",
  SET_CAR_YEARS="SET_CAR_YEARS",
  SET_QL_ENABLE="SET_QL_ENABLE",
  SET_APPROVAL_INVOICE_LIST_COUNT="setApprovalInvoiceListCount",
  SET_MANUAL_QUOTE_LOADING="SET_MANUAL_QUOTE_LOADING",
  SET_CANCEL_MODAL = "SET_CANCEL_MODAL",
  SET_EXTENSION_IS_OPEN="SET_EXTENSION_IS_OPEN",
  SET_EXTENSIONS="SET_EXTENSIONS",
  SET_CATEGORY_REFUND="SET_CATEGORY_REFUND",
  SET_REFUND_INVOICE_ID="SET_REFUND_INVOICE_ID",
  SET_EMAIL_EXIST_ERROR="SET_EMAIL_EXIST_ERROR",
  SET_AMEND_ERROR="SET_AMEND_ERROR",
  SET_ACTIVE_UNDERWRITERS="SET_ACTIVE_UNDERWRITERS",
  SET_CUSTOMER_LEADS_FOR_MERGE="setCustomerLeadForMerge",
  SET_INITIATE_AMEND_REFUND="SET_INITIATE_AMEND_REFUND",
  SET_REPORT_LEAD_STATUS_LIST = "setReportLeadStatusList",
  SET_REPORT_SALES_MADE_LIST = "setReportSalesMadeList",
  SET_DASHBOARD_DETAILS_REPORT_LIST = "setDashboardDetailsReportList",
  SET_EXPORT_DASHBOARD_DETAILS_REPORT_LIST = "setExportDashboardDetailsReportList",
  SET_VIEW_QUICK_LEAD = "setViewQuickLead",
  SET_CHECK_TRIM = "setCheckTrim",
  SET_GRAPH_PAID_VS_ORAGNIC_LEADS = "setGraphPaidVsOrganicLeads",
  SET_GRAPH_PAID_VS_ORAGNIC_DEALS = "setGraphPaidVsOrganicDeals",
  SET_GRAPH_ORAGNIC_UTM_LEADS = "setGraphOrganicUTMLeads",
  SET_GRAPH_ORAGNIC_UTM_DEALS = "setGraphOrganicUTMDeals",
  SET_GRAPH_PAID_UTM_LEADS = "setGraphPaidUTMLeads",
  SET_GRAPH_PAID_UTM_DEALS = "setGraphPaidUTMDeals",
  SET_GRAPH_LEAD_SOURCE_LEADS = "setGraphLeadSourceLeads",
  SET_GRAPH_LEAD_SOURCE_DEALS = "setGraphLeadSourceDeals",
  SET_UPDATE_DATA_POLICY = "setUpdateDataPolicy",
  SET_GRAPH_PAID_CAMPAIGN_LEADS = "setGraphUtmCampaignLeads",
  SET_GRAPH_PAID_CAMPAIGN_DEALS = "setGraphUtmCampaignDeals",
  SET_TOTAL_WIP_SALES = "setTotalWipSales",
  SET_GRAPH_ORGANIC_CAMPAIGN_DEALS = "setOrganicCampaignDeals",
  SET_GRAPH_ORGANIC_CONTENT_DEALS = "setOrganicContentDeals",
  SET_GRAPH_PAID_CONTENT_DEALS = "setPaidContentDeals",
  SET_GRAPH_PAID_CONTENT_LEADS = "setPaidContentLeads",
  SET_SPLIT_PAYMENTS_DOM = "setSplitPaymentsDom",
  SET_SPLIT_PAYMENT_LIST = "setSplitPaymentList",
  SET_SPLIT_PAYMENT_LIST_ERROR = "setSplitPaymentListError",
  SET_SPLIT_PAYMENT_MODAL = "setSplitPaymentModal",
  SET_SPLIT_PAYMENT_MODAL_LOADING = "setSplitPaymentModalLoading",
  SET_SPLIT_PAYMENT_MODAL_VALUE = "setSplitPaymentModalValue",
  SET_NEW_AUTH_CHECK = "SET_NEW_AUTH_CHECK",
  SET_SPLIT_PAYMENT_UPDATE_ERROR = "SET_SPLIT_PAYMENT_UPDATE_ERROR",
  SET_CUSTOMER_BOUGHT_FROM_LIST = "setCustomerBoughtFromList",
}

export { Actions, Mutations };


import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";


export interface DomInfo {
  showDom: boolean;
  showQuotesDom: boolean;
  showInvoiceDom: boolean;
  showLeadLogDom: boolean;
  showPolicyDocumentDom: boolean;
  showLeadListDom: boolean;
  showPolicyListDom: boolean;
  showUserDom: boolean;
  showManualQuotesDom: boolean;
}

@Module
export default class DomModule extends VuexModule implements DomInfo {
  showDom = true;
  showQuotesDom = true;
  showInvoiceDom = true;
  showLeadLogDom = true;
  showPolicyDocumentDom = true;
  showLeadListDom = true;
  showUserDom = true;
  showEditDriverDetailsDom = true;
  disableButton = false;
  showManualQuotesDom = true;
  showPolicyListDom = true;
  showInvoiceDocumentDom = true;
  showInvoiceApprovalDom = true;
  disableCustomerEmailButton = false;
  disablePolicySaveButton = false;
  disablePolicyDraftSaveButton = false;
  disableBrokerEmailButton = false;
  disableSaleTransferButton = false;
  showPolicyDocumentModalDom = true;
  showPolicyDom = true;
  showRefundDom = true;
  CancelLoading = false
  openViewAllDocuments = false;
  loadingViewAllDocuments = false;
  dataViewAllDocuments = []
  showSplitPaymentsDom = false;
  

  @Mutation
  [Mutations.SET_DOM_STATUS](payload) {
    this.showDom = payload;
  }
  @Mutation
  [Mutations.SET_QUOTES_DOM_STATUS](payload) {
    this.showQuotesDom = payload;
  }
  @Mutation
  [Mutations.SET_INVOICE_DOM_STATUS](payload) {
    this.showInvoiceDom = payload;
  }
  @Mutation
  [Mutations.SET_LEAD_LOG_DOM_STATUS](payload) {
    this.showLeadLogDom = payload;
  }
  @Mutation
  [Mutations.SET_POLICY_DOCUMENT_DOM_STATUS](payload) {
    this.showPolicyDocumentDom = payload;
  }
  @Mutation
  [Mutations.SET_POLICY_DOM_DETAILS](payload) {
    this.showPolicyDom = payload;
  }
  @Mutation
  [Mutations.SET_REFUND_DOM_DETAILS](payload) {
    this.showRefundDom = payload;
  }
  @Mutation
  [Mutations.SET_LEAD_LIST_DOM_STATUS](payload) {
    this.showLeadListDom = payload;
  }
  @Mutation
  [Mutations.SET_USER_DOM](payload) {
    this.showUserDom = payload;
  }
  @Mutation
  [Mutations.SET_EDIT_DRIVER_DETAILS_DOM](payload) {
    this.showEditDriverDetailsDom = payload;
  }
  @Mutation
  [Mutations.SET_DISABLE_BUTTON](payload) {
    this.disableButton = payload;
  }
  @Mutation
  [Mutations.SET_CANCEL_LOADING](payload) {
    this.CancelLoading = payload;
  }
  @Mutation
  [Mutations.SET_CUSTOMER_EMAIL_DISABLE_BUTTON](payload) {
    this.disableCustomerEmailButton = payload;
  }
  @Mutation
  [Mutations.SET_BROKER_EMAIL_DISABLE_BUTTON](payload) {
    this.disableBrokerEmailButton = payload;
  }

  @Mutation
  [Mutations.SET_MANUAL_QUOTES_DOM_STATUS](payload) {
    this.showManualQuotesDom = payload;
  }
  @Mutation
  [Mutations.SET_INVOICE_DOCUMENT_DOM](payload) {
    this.showInvoiceDocumentDom = payload;
  }
  @Mutation
  [Mutations.SET_INVOICE_APPROVAL_DOM](payload) {
    this.showInvoiceApprovalDom = payload;
  }

  @Mutation
  [Mutations.SET_DISABLE_TRANSFER_BUTTON](payload) {
    this.disableSaleTransferButton = payload;
  }
  @Mutation
  [Mutations.SET_POLICY_LIST_DOM](payload) {
    console.log({dom: payload})
    this.showPolicyListDom = payload;
  }
  @Mutation
  [Mutations.SET_POLICY_SAVE_BUTTON_DISABLED](payload) {
    this.disablePolicySaveButton = payload;
  }

  @Mutation
  [Mutations.SET_DATA_VIEW_ALL_DOCUMENTS](payload) {
    this.dataViewAllDocuments = payload;
  }

  @Mutation
  [Mutations.SET_OPEN_VIEW_ALL_DOCUMENTS](payload) {
    this.openViewAllDocuments = payload;
  }

  @Mutation
  [Mutations.SET_LOADING_VIEW_ALL_DOCUMENTS](payload) {
    this.loadingViewAllDocuments = payload;
  }

  @Mutation
  [Mutations.SET_POLICY_DRAFT_SAVE_BUTTON_DISABLED](payload) {
    this.disablePolicyDraftSaveButton = payload;
  }
  
  @Mutation
  [Mutations.SET_POLICY_DOCUMENT_MODAL_DOM_STATUS
  ](payload) {
    this.showPolicyDocumentModalDom = payload;
  }

  @Mutation
  [Mutations.SET_SPLIT_PAYMENTS_DOM](payload) {
    this.showSplitPaymentsDom = payload;
  }

  get getUserDomStatus(): any {
    return this.showUserDom;
  }
  get getQuotesDomStatus(): any {
    return this.showQuotesDom;
  }
  get getDomStatus(): any {
    return this.showDom;
  }
  get getInvoiceDomStatus(): any {
    return this.showInvoiceDom;
  }
  get getLeadLogDomStatus(): any {
    return this.showLeadLogDom;
  }

  get getPolicyDocumentDomStatus(): any {
    return this.showPolicyDocumentDom;
  }
  get getShowLeadDomStatus(): any {
    return this.showLeadListDom;
  }
  get getShowEditDriverDetailsStatus(): any {
    return this.showEditDriverDetailsDom;
  }
  get getDisableButtonStatus(): any {
    return this.disableButton;
  }
  get getCancelLoading(): any {
    return this.CancelLoading;
  }
  get getDisableCustomerEmailButton(): any {
    return this.disableCustomerEmailButton;
  }

  get getDisableBrokerButton(): any {
    return this.disableBrokerEmailButton;
  }
  get getManualQuotesDomStatus(): any {
    return this.showManualQuotesDom;
  }
  get getInvoiceDocumentDom(): any {
    return this.showInvoiceDocumentDom;
  }
  get getApprovalListingDom(): any {
    return this.showInvoiceApprovalDom;
  }
  get getDisableTransferSale(): any {
    return this.disableSaleTransferButton;
  }
  get getPolicyListDom(): any {
    return this.showPolicyListDom;
  }
  get getPolicyDocumentModalDomStatus(): any {
    return this.showPolicyDocumentModalDom;
  }
  get getPolicyDomStatus(): any{
    return this.showPolicyDom;
  }
  get getRefundDomStatus(): any{
    return this.showRefundDom;
  }
  get getPolicyButtonStatus(): any{
    return this.disablePolicySaveButton;
  }
  get getPolicyDraftButtonStatus(): any{
    return this.disablePolicyDraftSaveButton;
  }
  get getOpenViewAllDocuments(): any{
    return this.openViewAllDocuments;
  }
  get getLoadingViewAllDocuments(): any{
    return this.loadingViewAllDocuments;
  }
  get getDataViewAllDocuments(): any{
    return this.dataViewAllDocuments;
  }
  get getSplitPaymentListingDom(): any {
    return this.showSplitPaymentsDom;
  }

}

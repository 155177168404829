import CustomerService from "@/core/services/car/CustomerService"

export async function useUpdateCustomerData(params) {
    return await CustomerService.withGlobalNew(params)
}

export async function useUpdateCustomerDataNew(params) {
    return await CustomerService.withGlobalNew(params)
}

export function useFixFigure(value) {
    if(value) {
        let figures = roundUp(parseFloat(value.toString().replace(',', '')))
        if(isNaN(figures)) figures = 0
        return figures
    }
}

function roundUp(num) {
    let p = Math.pow(10, 2);
    return Math.round(num * p) / p;
}

export function useClearPhoneNumber(customer) {
    // customer.phone_number = ''
    // Customer.clearExistCustomer()
}

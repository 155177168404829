import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";
import { useRoleBasedConditions, useUser } from '@/store/composable/User'
import moment from "moment"
import ApiService from "@/core/services/ApiService";
import { useStore } from 'vuex';


@Module
export default class Customer extends VuexModule {
    // Actions
    @Action
    async ['WITH_GLOBAL_NEW'](payload) {
        try {
            // console.log('payload.linkType',payload.linkType)
            let url = getLink(payload.linkType)
            if(url.includes('PAGE_NUMBER')) url = url.replace('PAGE_NUMBER', payload.page)
            if(url.includes('USER_ID')) url = url.replace('USER_ID', payload.user_id)
            delete payload.linkType
            delete payload.page

            const response = await ApiService.post(url, payload)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async['AMEND_INVOICE'] (payload) {
        try {
            const response = await ApiService.post_form('/skye/invoice/add-amend-invoice', payload)
            return response
        } catch (error:any) {
            return error.response
        }
    }

    @Action
    async['UPLOAD_RECEIPT'] (payload) {
        try {
            const response = await ApiService.post_form('/skye/invoice/add-invoice-document', payload)
            return response
        } catch (error:any) {
            return error.response
        }
    }
    


}

function getLink(value: string) {
    switch(value) {
        
        case 'master-policy-status': return '/skye/master/policy-statuses'
        case 'payment-types': return '/skye/master/payment-types'
        case 'master-underwriter-list': return '/skye/underwriter-list'
        case 'agent-list-dom': return '/skye/agent-list-for-dom-new'
        case 'underwriter-list': return '/skye/underwriter-list'
        case 'marketing-spend': return '/skye/marketing/campaigns/spend/filters'
        case 'marketing-spend-tracking': return '/skye/marketing/campaigns/spend/tracking'
        case 'marketing-spend-add-update': return '/skye/marketing/campaigns/spend/addUpdate'
        case 'marketing-report': return '/skye/reports/campaign-report'
        case 'marketing-report-agent': return '/skye/reports/campaign-report'
        case 'lead-source-filter': return '/skye/reports/master/lead-source-filter'
        case 'lead-by-source-filter': return '/skye/reports/master/lead-by-source-filters'
        case 'user-list-for-dom': return '/skye/users-list-for-dom'
        case 'get-active-inactive-agents': return '/skye/get-active-inactive-agents'
        case 'renewal-status': return '/skye/reports/renewal-status?page=PAGE_NUMBER'
        case 'lost-lead-report': return '/skye/reports/lost-lead?page=PAGE_NUMBER'
        case 'agent-escalations': return '/skye/reports/agent-escalations?page=PAGE_NUMBER'
        case 'underwriter-escalations': return '/skye/reports/underwriter-escalations?page=PAGE_NUMBER'
        case 'master-lead-status': return '/skye/master/list-car-lead-statuses'
        case 'sales-tracking-report': return '/skye/reports/sales-tracking-report'
        case 'get-upload-receipt': return '/skye/invoice/get-receipt-details'
        case 'reset-password': return '/skye/user-reset-password'
        case 'request-reset-password': return '/skye/user-request-reset-password'
        case 'agent-lead-activity-report': return '/skye/reports/agent-lead-activity-report?page=PAGE_NUMBER'
        case 'user-roles': return '/skye/user-roles'
        case 'roundrobin-listing': return '/skye/user/roundrobin-listing'
        case 'update-roundrobin-setting': return '/skye/user/update-roundrobin-setting'
        case 'check-if-promo-exists': return '/skye/promotions/validate/exists'
        case 'validate-promo-use': return '/skye/promotions/validate/code'
        case 'users-list': return 'skye/users-list?page=PAGE_NUMBER'
        case 'user-detail': return '/skye/user/show/USER_ID'
        case 'update-user': return '/skye/user/update/USER_ID'
        case 'add-user': return '/skye/user-create'
        case 'team-leader-list': return '/skye/team-leader-list'        
        case 'get-agent-setting-list': return '/skye/get-agent-setting-list'
        case 'switch-user-link': return '/skye/switch-user-link'
        case 'get-switch-app-dropdown': return '/skye/get-switch-app-dropdown'
        case 'policy-approval': return '/skye/invoice/list-approval-invoice-v3?page=PAGE_NUMBER'
        case 'has-child-invoice': return '/skye/invoice/get-child-invoices-v3'
        case 'declined-invoice':
        case 'invoice-list': return '/skye/invoice/list-invoice-v3?page=PAGE_NUMBER'
        case 'list-invoice-count': return '/skye/invoice/list-invoice-count'
        case 'invoice-for-approval-count': return '/skye/invoice/list-approval-invoice-count'
        case 'invoice-data': return '/skye/invoice/get-online-payment-info'

        default: return ''
    }
}

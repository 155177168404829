import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";
import { useUser } from '@/store/composable/User'

interface keyVariable {
    [key: string] : string | number | undefined
}

const { role_id, user_id } = useUser()

@Module
export default class Listing extends VuexModule {

    get getRoundRobinUserLists() {
        return (data) => {
            if(data) {
                return data.map(x => {
                    const round_robin_updated_by = x.round_robin_setting && x.round_robin_setting.round_robin_updated_by_user ? x.round_robin_setting.round_robin_updated_by_user.name : '-';
                    const round_robin_updated_on = x.round_robin_setting && x.round_robin_setting.round_robin_updated_at_format != null ? x.round_robin_setting.round_robin_updated_at_format : '-';
                    return {
                        id: x.id,
                        name: x.name,
                        username: x.email,
                        official_email: x.official_email,
                        role: x.role.name,
                        status: x.user_status,
                        skye_type: x.skye_type,
                        is_round_robin: x.round_robin_setting ? x.round_robin_setting.is_round_robin : 0,
                        round_robin_updated_values: `${round_robin_updated_by}<br />${round_robin_updated_on}`,
                    }
                })
            }
        }
    }

    get getUserLists() {
        return (data) => {
            if(data) {
                return data.map(x => {
                    return {
                        id: x.id,
                        name: x.name,
                        official_email: x.official_email,
                        username: x.email,
                        role_id: x.role_id,
                        role: x.role.name,
                        status: x.user_status,
                        created_updated: `${x.created_at}<br />${x.updated_at}`,
                        edit_path: `/user/edit/${x.id}`,
                        switch_path: `/user/switch/${x.id}`,
                    }
                })
            }
        }
    }

}
import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import CustomerModule from "@/store/modules/car/CustomerModule";
import QuotesModule from "@/store/modules/car/QuotesModule";
import InvoiceModule from "@/store/modules/car/InvoiceModule";
import TaskModule from "@/store/modules/car/TaskModule";
import NotesModule from "@/store/modules/car/NotesModule";
import LeadModule from "@/store/modules/car/LeadModule";
import UserModule from "@/store/modules/UserModule";
import MasterModule from "@/store/modules/car/MasterModule";
import DomModule from "@/store/modules/DomModule";
import PolicyModule from "@/store/modules/car/PolicyModule";
import EmailModule from "@/store/modules/car/EmailModule";
import NotificationModule from "@/store/modules/NotificationModule";
import SmsModule from "@/store/modules/car/SmsModule";
import PromotionCodeModule from "@/store/modules/PromotionCodeModule";
import DashboardModule from "@/store/modules/car/DashboardModule";
import SalesModule from "@/store/modules/car/SalesModule";
import ManualOrderModule from "@/store/modules/car/ManualOrderModule";
import ReportModule from "@/store/modules/car/ReportModule";
import AchievementModule from "@/store/modules/AchievementModule";
import SplitPaymentModule from "@/store/modules/car/SplitPaymentModule";

/**
 * Optimize
 */
import Customer from "./modules/car/Customer";
import Report from "./modules/car/Report";
import Listing from "./modules/car/Listing";
import User from "./modules/car/User";

config.rawError = true;
const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    CustomerModule,
    QuotesModule,
    TaskModule,
    NotesModule,
    InvoiceModule,
    LeadModule,
    UserModule,
    PolicyModule,
    MasterModule,
    DomModule,
    EmailModule,
    SmsModule,
    NotificationModule,
    PromotionCodeModule,
    DashboardModule,
    SalesModule,
    ManualOrderModule,
    ReportModule,
    AchievementModule,
    Customer,
    SplitPaymentModule,
    Report,
    Listing,
    User,
  },
  state:{
    customerList:[],
  },
  mutations:{
  }, 
  actions: {

  }
});

export default store;
